
import * as vercelRuntime$b8uWPRx1qM from '/vercel/path0/node_modules/@nuxt/image/dist/runtime/providers/vercel'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {
    "hero": {},
    "blogThumbs": {
      "modifiers": {
        "quality": "80",
        "format": [
          "webp",
          "avif",
          "jpg"
        ],
        "densities": "x1 x2"
      }
    },
    "blogHero": {}
  },
  "provider": "vercel",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['vercel']: { provider: vercelRuntime$b8uWPRx1qM, defaults: {} }
}
        